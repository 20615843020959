import React from "react";
import './blocks.styles.css'
// @ts-ignore
import {default as EyeIcon} from "../../../assets/icons/eye.icon.svg"
// @ts-ignore
import {default as CloudIcon} from "../../../assets/icons/cloud.icon.svg"
// @ts-ignore
import {default as DesignIcon} from "../../../assets/icons/design.icon.svg"
import QRCode from 'qrcode.react';

// @ts-ignore
import {default as arrow} from "../../../assets/icons/arrow.icon.svg"

// @ts-ignore
import {default as share} from "../../../assets/icons/share2.icon.svg"

// @ts-ignore
import {default as email} from "../../../assets/icons/email.icon.svg"

// @ts-ignore
import {default as sms} from "../../../assets/icons/sms.icon.svg"

// @ts-ignore
import {default as bizzerdLogo} from "../../../assets/img/bizzerd_logo.png"
// @ts-ignore
import {default as CardsBackground} from "../../../assets/containers/small.container.png"
import isEmpty from "../../../validation/is-empty";

import PageTitle from "../fields/text/pagetitle.text";
import SubTitle from "../fields/text/subtitle.text";


export interface shareBlockProps {
    realWidth: number,
    bizzerdLayout: any,
    personContent: any
}


class ShareBlock extends React.Component<shareBlockProps, {}> {

    getMobileOperatingSystem = () => {
        // @ts-ignore
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        // @ts-ignore
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return "iOS";
        }

        return "Android";
    }

    resize = (value) => {
        let realWidth = 640;
        if (this.props.realWidth !== undefined) {
            realWidth = this.props.realWidth;
        }
        return value * realWidth / 640;
    };

    getURL = () => {
        let shouldAddMed = window.location.href.includes("?med=nfc")
        const urlArray = window.location.href.split('/').slice();
        urlArray.pop();
        let url = urlArray.join('/');
        if (shouldAddMed) {
            url = url + "?med=nfc"
        }
        return url;
    };

    render() {
        let style: any = {
            padding: this.resize(50)
        }
        const shareBlockStyle: any = {
            padding: this.resize(50)
        }
        if (!isEmpty(this.props.bizzerdLayout) && this.props.bizzerdLayout.background.length > 0) {
            let minHeight = this.resize(this.props.bizzerdLayout.background[0].height)
            style.minHeight = minHeight > 600 ? minHeight : 600 + 'px';
            style.width = this.resize(this.props.bizzerdLayout.background[0].width) + "px";
            style.minWidth = 300;
            shareBlockStyle.minWidth = this.resize(this.props.bizzerdLayout.background[0].height) + 'px';

        }

        const smallButtonStyle = {
            height: this.resize(100),
            minHeight: 100
        }

        const bigButtonStyle = {
            height: this.resize(60),

            minHeight: 50
        }
        const headStyle = {
            height: this.resize(50),
        };

        return (
            <div className={"single-block-container"}>
                <div className={"share-block"} style={style}>
                    <div style={headStyle} className="share-header">
                        <a href={this.getURL()} className={"share-back-button"} style={{left: this.resize(20)}}>
                            <div style={{display: "flex", alignItems: "center"}}><img
                                style={{height: this.resize(20), width: this.resize(20)}}
                                src={arrow}/>{" Back"}</div>
                        </a>
                        <PageTitle text={"Share"}/>
                    </div>
                    <div className={"share-block-content"}>
                        {/*<img style={{width: this.resize(200) > 120 ? this.resize(200) : 120}} src={bizzerdLogo}/>*/}
                        <br/>
                        <SubTitle text={this.props.personContent.fullname}/>
                        <span>{this.props.personContent.jobtitle}</span>
                        <div className={"qr-code-container"} style={{marginTop: this.resize(40)}}>
                            <QRCode bgColor="#ffffff" fgColor="#000000"
                                    size={this.resize(200) > 120 ? this.resize(200) : 120}
                                    value={this.getURL() + "?mode=qr"}/>
                        </div>

                        <span
                            style={{textAlign: "center"}}>Scan de QR-code om het kaartje op je mobiel te ontvangen</span>
                        {navigator.share ? <a style={{
                            width: "100%",
                            cursor: "pointer",
                            marginTop: this.resize(60),
                            marginBottom: this.resize(40)
                        }} onClick={() => {
                            if (window.self === window.top) {
                                if (navigator.share) {
                                    navigator.share({
                                            title: 'Digitaal visitekaartje',
                                            text: 'Bekijk mijn digitale visitekaartje',
                                        url: this.getURL() + "?mode=share",
                                    })
                                        .then(() => console.log('Successful share'))
                                        .catch((error) => console.log('Error sharing', error));
                                }
                            }else {
                                window.parent.postMessage({ action: 'share', url: this.getURL() + "?mode=share" }, "*");
                            }

                        }}>
                            <div className={"share-button"} style={bigButtonStyle}>

                                <img style={{height: this.resize(30), width: this.resize(50)}} src={share}/>
                                Deel kaartje
                            </div>
                        </a> : <div style={{marginTop: this.resize(160)}}></div>}
                        <div className={"share-button-row-container"}>
                            <div style={{width: "45%"}}>
                                <a style={{width: "100%"}}
                                   href={this.getMobileOperatingSystem() === "Android" ? "sms:31612345678?body=Beste ontvanger,%0D%0A%0D%0ABekijk hier mijn digitale visitekaartje:%0D%0A" +
                                       this.getURL() + "?mode=email" + "%0D%0A%0D%0AMet vriendelijke groet,%0D%0A" +
                                       this.props.personContent.fullname : "sms:31612345678&body=Beste ontvanger,%0D%0A%0D%0ABekijk hier mijn digitale visitekaartje:%0D%0A" +
                                       this.getURL() + "?mode=email" + "%0D%0A%0D%0AMet vriendelijke groet,%0D%0A" +
                                       this.props.personContent.fullname}>
                                    <div className={"share-button-small"} style={smallButtonStyle}>
                                        <img style={{
                                            width: this.resize(40),
                                            height: this.resize(40),
                                            marginBottom: this.resize(20)
                                        }} src={sms}/>
                                        Verstuur via SMS
                                    </div>
                                </a>
                            </div>
                            <div style={{width: "45%"}}>
                                <a style={{width: "100%"}}
                                   href={"mailto:?subject=Bekijk%20mijn%20digitale%20visitekaartje&body=Beste%20ontvanger,%0D%0A%0D%0ABekijk%20hier%20mijn%20digitale%20visitekaartje:%0D%0A" +
                                       this.getURL() + "?mode=email" + "%0D%0A%0D%0AMet%20vriendelijke%20groet,%0D%0A" +
                                       (this.props.personContent.fullname ? this.props.personContent.fullname.replace(" ", "%20").replace(" ", "%20").replace(" ", "%20").replace(" ", "%20") : "")}>

                                    <div className={"share-button-small"} style={smallButtonStyle}>
                                        <img style={{
                                            width: this.resize(40),
                                            height: this.resize(40),
                                            marginBottom: this.resize(20)
                                        }} src={email}/>
                                        Verstuur via E-mail

                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        );
    }

}


export default ShareBlock;